const area = {
    areaList:{
        "province_list": {
          "140000": "山西省"
        },
        "city_list": {
          "140100": "太原市",
          "140200": "大同市",
          "140300": "阳泉市",
          "140400": "长治市",
          "140500": "晋城市",
          "140600": "朔州市",
          "140700": "晋中市",
          "140800": "运城市",
          "140900": "忻州市",
          "141000": "临汾市",
          "141100": "吕梁市"
        },
        "county_list": {
          "140101": "小店区",
          "140102": "迎泽区",
          "140103": "杏花岭区",
          "140104": "尖草坪区",
          "140105": "万柏林区",
          "140106": "晋源区",
          "140107": "清徐县",
          "140108": "阳曲县",
          "140109": "娄烦县",
          "140110": "古交市",
          "140201": "市辖区",
          "140202": "城区",
          "140203": "矿区",
          "140211": "南郊区",
          "140212": "新荣区",
          "140221": "阳高县",
          "140222": "天镇县",
          "140223": "广灵县",
          "140224": "灵丘县",
          "140225": "浑源县",
          "140226": "左云县",
          "140227": "大同县",
          "140301": "市辖区",
          "140302": "城区",
          "140303": "矿区",
          "140311": "郊区",
          "140321": "平定县",
          "140322": "盂县",
          "140401": "市辖区",
          "140402": "城区",
          "140411": "郊区",
          "140421": "长治县",
          "140423": "襄垣县",
          "140424": "屯留县",
          "140425": "平顺县",
          "140426": "黎城县",
          "140427": "壶关县",
          "140428": "长子县",
          "140429": "武乡县",
          "140430": "沁县",
          "140431": "沁源县",
          "140481": "潞城市",
          "140501": "市辖区",
          "140502": "城区",
          "140521": "沁水县",
          "140522": "阳城县",
          "140524": "陵川县",
          "140525": "泽州县",
          "140581": "高平市",
          "140601": "市辖区",
          "140602": "朔城区",
          "140603": "平鲁区",
          "140621": "山阴县",
          "140622": "应县",
          "140623": "右玉县",
          "140701": "市辖区",
          "140702": "榆次区",
          "140721": "榆社县",
          "140722": "左权县",
          "140723": "和顺县",
          "140724": "昔阳县",
          "140725": "寿阳县",
          "140726": "太谷县",
          "140727": "祁县",
          "140728": "平遥县",
          "140729": "灵石县",
          "140781": "介休市",
          "140801": "市辖区",
          "140802": "盐湖区",
          "140821": "临猗县",
          "140822": "万荣县",
          "140823": "闻喜县",
          "140824": "稷山县",
          "140825": "新绛县",
          "140826": "绛县",
          "140827": "垣曲县",
          "140828": "夏县",
          "140829": "平陆县",
          "140830": "芮城县",
          "140881": "永济市",
          "140882": "河津市",
          "140901": "市辖区",
          "140902": "忻府区",
          "140921": "定襄县",
          "140922": "五台县",
          "140923": "代县",
          "140924": "繁峙县",
          "140925": "宁武县",
          "140926": "静乐县",
          "140927": "神池县",
          "140928": "五寨县",
          "140929": "岢岚县",
          "140930": "河曲县",
          "140931": "保德县",
          "140932": "偏关县",
          "140981": "原平市",
          "141001": "市辖区",
          "141002": "尧都区",
          "141021": "曲沃县",
          "141022": "翼城县",
          "141023": "襄汾县",
          "141024": "洪洞县",
          "141025": "古县",
          "141026": "安泽县",
          "141027": "浮山县",
          "141028": "吉县",
          "141029": "乡宁县",
          "141030": "大宁县",
          "141031": "隰县",
          "141032": "永和县",
          "141033": "蒲县",
          "141034": "汾西县",
          "141081": "侯马市",
          "141082": "霍州市",
          "141101": "市辖区",
          "141102": "离石区",
          "141121": "文水县",
          "141122": "交城县",
          "141123": "兴县",
          "141124": "临县",
          "141125": "柳林县",
          "141126": "石楼县",
          "141127": "岚县",
          "141128": "方山县",
          "141129": "中阳县",
          "141130": "交口县",
          "141181": "孝义市",
          "141182": "汾阳市"
        }
      }
      
}
export default area;