<template>
    <div class="bbb">
        <div id="Interrogation" class="post_Patient">
          <van-nav-bar
                title="添加就诊人"
                left-arrow
                @click-left="onClickLeft"
            />
            <van-field v-model="form.name" label="姓名" :disabled="id != ''" placeholder="请输入就诊人姓名" @input="changename" />
            <van-cell title="性别" :disabled="id != ''" @click="Select('sexShow')" v-show="false"  :value="form.sex" />
            <van-cell title="出生年月" :disabled="id != ''" @click="Select('birthdayShow')" v-show="false" :value="form.birthday" />
            <van-cell value-class="flex-align-end" :disabled="id != ''" @click="show = true" :label="idCardCheck(form.cerno) ? '身份证号正确' : '请输入正确的身份证号'  ">
                    <template slot="title">
                        <div class="flex-align">
                            <span >证件号 </span>
                        </div>
                    </template>
                    <template>
                        <span :class="idCardCheck(form.cerno) ? 'required_success' : 'required'">{{form.cerno }}</span>
                    </template>
            </van-cell>
            <van-number-keyboard
            :show="show"
            extra-key="X"
            close-button-text="完成"
            @blur="show = false"
            @input="onInput"
            @delete="onDelete"
              />

            <van-cell v-if="id == ''" title="现住地址" @click="areaShow = true" :value="areaValue" />
            <van-field v-if="id == ''" v-model="form.address " label="详细地址" placeholder="请输入就诊人详细地址" />
            <van-cell v-if="id == ''" title="与我关系" @click="Select('relationShow')" :value="form.relation" />
            <div v-if="id == ''" style="display: flex;margin-top: 15px;text-align: center;">
              <div style="width: 50%;">
                <van-uploader  :max-count="1" class="margin_top" v-model="IDcardImgUrl" multiple :after-read="afterRead">
                  <img style="width: 40vw;height: 6rem;" src="../assets/image/IDcard.png" alt="">
                </van-uploader >
                <p class="text pading10 " style="line-height: 22px;margin-top: 15px;">上传身份证识别信息</p>
              </div>
              <div  style="width: 50%;">
                <van-uploader  :max-count="1" class="margin_top" v-model="security_cardImgUrl" multiple image-fit="fill" :after-read="security_cardAfterRead">
                  <img style="width: 40vw;height: 6rem" src="../assets/image/healthcare.jpg" alt="">
                </van-uploader>
                <p class="text pading10 " style="line-height: 22px;margin-top: 15px;">上传医保卡识别信息</p>
              </div>
            </div>
            <div v-if="hospital_name !==''">
              <van-field v-model="hospital_name" label="医院名称"  placeholder=""  />
              <van-field v-model="patient_disease" label="疾病名称"  placeholder=""  />
            </div>
            <el-upload
            v-if="id != '' && img==''"
            class="upload-demo"
            :action="upload_url"
            :on-success="onSuccess"
            method="post"
            multiple
          
            >
            <van-button  style="margin-top: 20px;text-align: center;width: 100%;"color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>上传复诊证明</van-button>
            </el-upload>
            <van-button v-if="id != '' && hospital_name !==''"  @click="createdAchives" style="margin-top: 20px;text-align: center;width: 100%;"color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>创建就诊人档案</van-button>










            <van-popup v-model="areaShow" position="bottom">
              <van-area  :area-list="areaList"  @confirm="confirm" />
            </van-popup>
            <van-action-sheet v-model="sexShow"  :actions="sexActions" @select="onSelect" />
            <van-action-sheet v-model="relationShow" :actions="relationList" @select="onSelect" />
            <van-action-sheet v-model="birthdayShow"  title="请选择出生年月">
              <van-datetime-picker
                v-model="birthday"
                type="date"
                :min-date="minDate"
                @confirm="onConfirm"
              />
            </van-action-sheet>
            <div class="m-top">
                <van-button v-if="id == ''" @click="Patient" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>绑定就诊人</van-button>
            </div>
        </div>
        <footer_/>
    </div>
  </template>
  <script>
  import footer_ from '../components/footer.vue'
  import area from '../assets/js/area.js'
  import imgFun from '../assets/js/img.js'
  import base from './api/base.js'
  export default {
    components: {
      footer_,
    },
    data() {
      return {
        form:{
            name:'',
            sex:'请选择就诊人性别',
            birthday:'1980-01-01',
            phone:'',
            cerno:'请输入就诊人证件号',
            pcode:'',
            pname:'',
            ccode:'',
            cname:'',
            acode :'',
            aname:'',
            address:'',
            relation:'本人'
        },
        hospital_name:'',
        patient_disease:'',
        IDcardImgUrl:[],
        security_cardImgUrl:[],
        currentDate: '',
        id:'',
        upload_url:'',
        minDate: new Date(1960, 6, 15),
        areaValue:'请选择现住地址',
        areaShow:false,
        show:false,
        sexShow:false,
        sexActions:[
        { name: '男',type:'sexShow',field:'sex' },
        { name: '女',type:'sexShow',field:'sex' },
        ],
        areaList:area.areaList,
        birthdayShow:false,
        birthday:'',
        relationShow:false,
        img:'',
        relationList:[
          { name: '本人',type:'relationShow',field:'relation' },
          { name: '父子',type:'relationShow',field:'relation' },
          { name: '子女',type:'relationShow',field:'relation' },
          { name: '兄妹',type:'relationShow',field:'relation' },
          { name: '配偶',type:'relationShow',field:'relation' },
          { name: '其他',type:'relationShow',field:'relation' },
        ]
         
      }
    },
    methods: {
      async onSuccess(response, file, fileList){
        let that = this
        this.loading = this.$loading({
            lock: true,//lock的修改符--默认是false
            text: '就诊信息识别中',    //显示在加载图标下方的加载文案
            color:'rgba(255, 255, 255, 1)',
            spinner: 'el-icon-loading',//自定义加载图标类名
            background: 'rgba(0, 0, 0, 0.9)',//遮罩层颜色
            target: document.querySelector('#table')//loadin覆盖的dom元素节点
        });
        setTimeout(() => {
            that.loading.close();
        }, 6000);
        console.log(response, file, fileList)
        this.$api.article.img_info(response.result.file_name,true).then(res=>{
          console.log(res.data.result)
          this.img = response.result.file_name
          this.hospital_name = res.data.result.hospital_name
          this.patient_disease = res.data.result.patient_disease.join(',')
          that.loading.close();
        })
      },
      createdAchives(){
        console.log(this.form)
          let params = {
                  search_db: 'Achives',
              }
              let pres_info = {
                hospital_name:this.hospital_name,
                patient_disease:this.patient_disease.split(','),
                medicine:[],
                imgs:[this.img]
              }
              let data = {
                  created:{
                      name:this.form.name,
                      project_share: JSON.parse(localStorage.getItem('PharmacyInfo')).project_id,
                      pres_info:JSON.stringify(pres_info),
                      patient_id:this.PatientInfo.found_id
                  }
              }
          this.$api.article.created(params,data).then(res=>{
            if(res.data.code == '200'){
              this.$message({
              message: '上传成功',
              type: 'success'
            });
              this.$router.back(-1);
            }
            
          })
      },
      changename(){
        this.form.name = this.form.name.replace(/[^\u4e00-\u9fa5]/g, '');
      },
      onInput(value){
        if(this.form.cerno == '请输入就诊人证件号'){
          this.form.cerno = ''
        }
        this.form.cerno += value
        if(this.idCardCheck(this.form.cerno)){
          this.form.sex = this.getGenderFromIdCard(this.form.cerno)
          this.form.birthday = this.form.cerno.substring(6, 10) + "-" + this.form.cerno.substring(10, 12) + "-" + this.form.cerno.substring(12, 14)
        }
      },
      idCardCheck(idCard){
        var idCardRegex = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        // 验证身份证号码的格式
        if (!idCardRegex.test(idCard)) {
          return false;
        }

        // 验证校验位
        var factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        var checkCode = "10X98765432";
        var sum = 0;
        for (var i = 0; i < 17; i++) {
          sum += parseInt(idCard.charAt(i)) * factors[i];
        }
        var index = sum % 11;
        if (idCard.charAt(17).toUpperCase() !== checkCode.charAt(index)) {
          return false;
        }

        return true;
        // var idCardRegex = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        // return idCardRegex.test(cerno)
        
      },
      onDelete(){
        if(this.form.cerno != '请输入就诊人证件号'){
          this.form.cerno = this.form.cerno.substring(0,this.form.cerno.length -1)
        }
        if(this.form.cerno == ''){
          this.form.cerno = '请输入就诊人证件号'
        }
      },
      //身份证识别
      afterRead(file, detail){
        let Blob = this.base64ToBlob(file[0].content)
        let formData = new FormData();
        formData.append('file',Blob,'123456.png');
        this.$api.article.upload('Patient',formData).then(res=>{
          this.IDcardImgUrl = [
            {
              url:`${this.url}img?static_path=Patient&file_name=${res.data.result.file_name}&token=${this.token}&userrole_id=${this.userrole_id}`
            }
          ]
          this.$api.article.ocrTxt(res.data.result.file_name,'Patient','idcrad').then(res=>{
            if(res.data.result.data){
              this.form.name = res.data.result.data.face.data.name
              this.form.birthday = res.data.result.data.face.data.birthDate
              this.form.cerno = res.data.result.data.face.data.idNumber
              this.form.sex = res.data.result.data.face.data.sex
            }
          })
        })
      },
      //医保卡识别
      security_cardAfterRead(file){
        let Blob = this.base64ToBlob(file[0].content)
        let formData = new FormData();
        formData.append('file',Blob,'123456.png');
        this.$api.article.upload('Patient',formData).then(res=>{
          this.security_cardImgUrl = [
            {
              url:`${this.url}img?static_path=Patient&file_name=${res.data.result.file_name}&token=${this.token}&userrole_id=${this.userrole_id}`
            }
          ]
          this.$api.article.ocrTxt(res.data.result.file_name,'Patient','security_card').then(res=>{
            if(res.data.result.data){
              this.form.name = res.data.result.data.name
              this.form.birthday = res.data.result.data.issueDate
              this.form.cerno = res.data.result.data.idNumber
              this.form.sex = this.getGenderFromIdCard(res.data.result.data.idNumber)
            }
          })
        })
      },
      getGenderFromIdCard(idCard){
        var genderDigit = parseInt(idCard.charAt(idCard.length - 2));
        // 判断性别
        if (genderDigit % 2 === 0) {
            return "女";
        } else {
            return "男";
        }
      },
      base64ToBlob(dataurl) {
          var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
          while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
      },
      onCancel(){
        this.birthdayShow = false;
        this.relationShow = false;
        this.sexShow = false;
        this.areaShow = false;
      },
      onConfirm(time) {
        this.form.birthday = this.formatter(time);
        this.birthdayShow = false;
      },
      formatter(time){
        let nowdata =time == null ?  new Date() : new Date(time);
        let year = nowdata.getFullYear();
        let month = nowdata.getMonth() + 1;
        let date = nowdata.getDate();
        let nowtime = year + "-";
        if (month < 10) {
          nowtime += "0";
        }
        nowtime += month + "-";
        if (date < 10) {
          nowtime += "0";
        }
        nowtime += date;

        return nowtime;
      },
      Select(type){
        this[type] = true
      },
      onSelect(item){
        this[item.type] = false
        this.form[item.field] = item.name
      },
      onClickLeft(){
        this.$router.back(-1)
      },
      confirm(event){
        console.log(event)
        this.form.pcode = event[0].code;
        this.form.pname = event[0].name;
        this.form.ccode = event[1].code;
        this.form.cname = event[1].name;
        this.form.acode = event[2].code;
        this.form.aname = event[2].name;
        this.areaValue = `${event[0].name}/${event[1].name}/${event[2].name}`
        console.log(this.areaValue)
        this.areaShow = false
      },
      Patient(){
        if(!this.idCardCheck(this.form.cerno)){
          this.$message.error('身份证号码输入错误请检查');
          return
        }
          let params = {
              search_db:'Patient',
          }
          let data = {
              created:JSON.parse(JSON.stringify(this.form))
          }
          data.created.birthday  = `${data.created.cerno.slice(6,10)}-${data.created.cerno.slice(10,12)}-${data.created.cerno.slice(12,14)}`
          data.created.sex  = this.getGenderFromIdCard(data.created.cerno)
          data.created.sex == '男' ? data.created.sex = 1 : data.created.sex = 0
          this.$api.article.created(params,data).then(res=>{
              if(res.data.code == '200'){
                this.$router.back(-1);
              }else{
                this.$message.error('创建失败,请重试');
              }
          })
      },
      putPatient(){
        if(!this.idCardCheck(this.form.cerno)){
          this.$message.error('身份证号码输入错误请检查');
          return
        }
          let params = {
              search_db:'Patient',
              query_type:'first'
          }
          let data = {
            update:JSON.parse(JSON.stringify(this.form)),
            query:{id:this.id}
          }
          data.update.birthday  = `${data.update.cerno.slice(6,10)}-${data.update.cerno.slice(10,12)}-${data.update.cerno.slice(12,14)}`
          data.update.sex  = this.getGenderFromIdCard(data.update.cerno)
          data.update.sex == '男' ? data.update.sex = 1 : data.update.sex = 0
          this.$api.article.update(params,data).then(res=>{
            console.log(res)
            if(res){
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.$router.back(-1);
            }else{
              this.$message.error('操作失败');
            }
          })
      },
      loadList(){
        let params = {
            search_db: 'Patient',
            query_type:'first'
        };
        let data = {
          query:[
            {
              fild:{id:this.$route.query.id},
              type:'=',
              condition:'&&'
            }
          ]
        }
        this.$api.article.query2(params,data).then(res=>{
          console.log(res)
          let userInfo = res.data.result
          if(res.data.result.pres_imgs == 'None'){
            res.data.result.pres_imgs = []
          }else{
            res.data.result.pres_imgs = JSON.parse(res.data.result.pres_imgs)
          }
          this.PatientInfo = res.data.result
          this.form = {
            name:userInfo.name,
            sex:userInfo.sex,
            birthday:userInfo.birthday,
            phone:userInfo.phone,
            cerno:userInfo.cerno,
            pcode:userInfo.pcode,
            pname:userInfo.pname,
            ccode:userInfo.ccode,
            cname:userInfo.cname,
            acode :userInfo.acode,
            aname:userInfo.aname,
            address:userInfo.address,
            relation:userInfo.relation
          }
          this.id = this.$route.query.id
          this.areaValue = `山西省/${area.areaList.city_list[userInfo.ccode]}/${area.areaList.county_list[userInfo.acode]}`
        })
      }
    },
    created() {
      let user_account = JSON.parse(localStorage.getItem('userinfo'))
      this.form.phone = user_account.username
      this.token = user_account.token
      this.userrole_id = localStorage.getItem('userrole_id')
      this.url = base.baseUrl
      console.log(this.$route.query.id)
      if(this.$route.query.id){
        
        this.loadList()
      }
      this.upload_url = `${base.baseUrl}img?static_path=Pres&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
    },
    mounted(){
   

    }
  }
  </script>
  <style scopde lang="less">
  .required{
        font-size: 13px;
        color: #F56C6C !important;
        font-weight: 600;
    }
    .required_success{
        font-size: 13px;
        color: #67C23A !important;
        font-weight: 600;
    }
    .PatientBtn{
        margin-top: 10rem !important;
    }
    .post_Patient .text{
      color: #000 !important;
    }
  </style>
  <style>
  .post_Patient .van-uploader__preview .van-image img{
    width: 40vw;
    height: 6rem;
  }
  .post_Patient .van-uploader__preview{
    width: 40vw;
    height: 6rem;
  }
  .el-upload{
    width: 100%;
  }
  /* .el-upload-list{
    display: none
  } */
  </style>